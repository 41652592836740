@import "colors.scss";

.borm-widget {
    margin-bottom: 20px;

    .borm-widget-title {
        background-color: $light;
        font-size: 20px;
        font-weight: bold;

        cursor: pointer;

        .icon-plus,
        .icon-minus {
            margin: 3px 4px;
        }

        .icon-cogwheel {
            margin: 6px 4px;
            float: right;
        }
    }

    .borm-widget-options {
        .borm-widget-reset-button {
            float: right;
            margin: 4px;

            .btn {
                border: 1px solid $default-border;
            }
        }
    }

    .borm-widget-load-more {
        .btn {
            width: 100%;
            border: 1px solid $default-border;
            text-align: center;
        }
    }
}